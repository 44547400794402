"use client";

import { reportInfo } from "@/common/application/debug";
import { prettyPrintObjectToString } from "@/common/application/prettyPrintObjectToString";
import accessAnalyticsStore from "@/features/analytics/application/analytics-store/analyticsStore";
import { AnalyticsEventData } from "@/features/analytics/domain/entities/AnalyticsTools";

interface ReportAnalyticsEventProps extends AnalyticsEventData {}

export const getClientId = async () => {
    const { tools } = accessAnalyticsStore();
    if (!tools) {
        return false;
    }
    return tools.clientId();
};

export const getSessionId = async () => {
    const { tools } = accessAnalyticsStore();
    if (!tools) {
        return false;
    }
    return tools.sessionId();
};

/**
 * Reports an analytics event using the current analytics integration. GA
 * automatically collects language, page location, page referrer, page title,
 * and screen resolution. All metadata top-level keys starting with [__] will
 * be filtered out.
 */
export const reportAnalyticsEvent = async (
    eventData: ReportAnalyticsEventProps
) => {
    const { tools } = accessAnalyticsStore();

    /**
     * ======================================
     * !!! Cannot use [reportError] or [reportLog] here, because these also
     * trigger analytics
     * !!! reporting. Only [reportInfo] allowed.
     * ======================================
     */

    const reportAnalyticsEventHelper = async () => {
        reportInfo(
            `Trying to report analytics event with the data: ${prettyPrintObjectToString(eventData)}.`
        );

        if (!tools) {
            reportInfo(
                "Could not report analytics event: no integration available."
            );
            return Promise.resolve();
        }

        const eventDataToReport: AnalyticsEventData = {
            ...eventData,
            /**
             * Override metadata, processing entries to only include those that
             * should be reported.
             */
            metadata: {
                ...Object.fromEntries(
                    /**
                     * Filter all metadata keys that start with [__].
                     */
                    Object.entries(eventData.metadata ?? {}).filter(
                        ([key]) => !key.startsWith("__")
                    )
                ),
            },
        };

        try {
            await tools.reportEvent(eventDataToReport);
        } catch (error) {
            reportInfo(
                "Could not report analytics event:",
                prettyPrintObjectToString(error)
            );
        }
    };

    return reportAnalyticsEventHelper();
};
