"use client";

import gsap from "gsap";
import { Draggable } from "gsap/Draggable";
import { Flip } from "gsap/Flip";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import { Observer } from "gsap/Observer";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useIsomorphicLayoutEffect } from "usehooks-ts";

/**
 * Registers animation plugins. Needed for GSAP and bundlers to include the
 * referenced plugins in the bundle.
 * @constructor
 */
export const RegisterAnimationPlugins = () => {
    useIsomorphicLayoutEffect(() => {
        gsap.registerPlugin(
            Observer,
            ScrollTrigger,
            ScrollToPlugin,
            MotionPathPlugin,
            Draggable,
            Flip
        );
    });

    return <></>;
};
