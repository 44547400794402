import {
    exclusivePageLayoutContentsStyle,
    exclusivePageLayoutStyle,
    mainContentStyle,
    sidebarStyle,
} from "@/component-library/components/page-layouts/exclusive/ExclusivePageLayout.css";
import { clsx } from "clsx";
import React from "react";

interface ExclusiveLayoutProps {
    header?: React.ReactNode;
    children: React.ReactNode;
    sidebar?: React.ReactNode;
}

/**
 * For content that takes the entire page. [ExclusiveLayoutContents] can be
 * used to wrap content inside the page, providing a scrollable and styled
 * setup for content.
 * @param header
 * @param children
 * @param sidebar
 * @constructor
 */
const ExclusivePageLayout: React.FC<ExclusiveLayoutProps> = ({
    header,
    children,
    sidebar,
}) => {
    return (
        <div className={exclusivePageLayoutStyle}>
            {header}
            <div className={clsx(exclusivePageLayoutContentsStyle)}>
                <main className={mainContentStyle}>{children}</main>
                {sidebar && <aside className={sidebarStyle}>{sidebar}</aside>}
            </div>
        </div>
    );
};

export default ExclusivePageLayout;
