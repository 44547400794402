import { PageApi } from "@/common/domain/entities/routing/PageApi";
import { constructRoute } from "@/common/application/routing/constructRoute";

/**
 * Generates the path to the route that will be used to render the results of a
 * search.
 * @param resource defines what kind of resource the user is looking for. You
 *   can find all available resources in [Wezoo] static class.
 * @param location defines the geographic location near which the user is
 *   looking for a resource.
 * @param filters define which filters the user has applied.
 * @param properties defines any key-value-pair filters or other properties
 *   that the user has applied. These are ignored in SEO.
 * @return relative path to the route from the web app root.
 */
interface DiscoverRouteGetPathProps {
    params?: Record<string, string | undefined>;
    host?: string;
    location?: string;
    type?: "ROOM" | "HOTDESK";
    coordinates?: {
        latitude: number;
        longitude: number;
        zoom?: number;
    };
}

/**
 * Used to generate paths to the resource search route on-demand. Use the
 * [getRoute] to create a path to the route with (optionally) parameters that
 * will be used in the path.
 */
export const DiscoverRouteApi: PageApi<DiscoverRouteGetPathProps> = {
    getRoute: ({ host, type, location, coordinates, params = {} }) => {
        if (host) params["host"] = host;
        if (location) params["location"] = location;
        if (type) params["type"] = type;
        if (coordinates) {
            params["latitude"] = coordinates.latitude.toString();
            params["longitude"] = coordinates.longitude.toString();
            if (coordinates.zoom) params["zoom"] = coordinates.zoom.toString();
        }
        return constructRoute(["discover"], params);
    },
};
