import { scrollToQueryParamKey } from "@/component-library/components/features/scroll-to-detector/scroll-to-query-param-key";

/**
 * Generates an absolute path to the app route specified by the arguments.
 * @param pathComponents - components of the path.
 * @param params - query params for the route.
 * @param scrollToId - id of the element to scroll to on the route load. Added as a query param.
 * Use [ScrollToDetector] to detect the scroll request in the target route component.
 */
export const constructRoute = (
    pathComponents: string[],
    params?: { [key: string]: string | undefined },
    scrollToId?: string
) => {
    const path = pathComponents.join("/");

    const paramsWithScrollToId = {
        ...params,
        [scrollToQueryParamKey]: scrollToId,
    };

    const queryString =
        Object.values(paramsWithScrollToId).length > 0
            ? Object.entries(paramsWithScrollToId)
                  .map(([key, value]) => {
                      if (value === undefined) {
                          return "";
                      }
                      return `${key}=${value}`;
                  })
                  .filter(
                      (queryStringComponent) => queryStringComponent.length > 0
                  )
                  .join("&")
            : undefined;

    const queryStringIfValidParams =
        queryString && queryString.length > 0 ? `?${queryString}` : "";

    return `/${path}${queryStringIfValidParams}`;
};
