import { WezooError } from "@/common/domain/errors/_base/WezooError";
import { ENVIRONMENT } from "@/common/utilities/env/ENVIRONMENT";
import { IS_DEBUG_ENVIRONMENT } from "@/common/utilities/env/IS_DEBUG_ENVIRONMENT";
import { reportAnalyticsEvent } from "@/features/analytics/application/reportAnalyticsEvent";
import { AnalyticsMetadataKeys } from "@/features/analytics/domain/constants/AnalyticsMetadataKeys";
import { AnalyticsEventType } from "@/features/analytics/domain/entities/AnalyticsEventType";

/**
 * Reports a debug message using the printing function in debug environment.
 * @param printingFunction - function used to print the args.
 * @param mode - Which mode of report is it.
 * @param args - arguments to print.
 */
const report = (
    printingFunction: (...args: any[]) => void,
    mode: "ERROR" | "LOG" | "INFO",
    ...args: any[]
) => {
    const now = new Date();
    if (IS_DEBUG_ENVIRONMENT) {
        // Only place where console is allowed to be used. The debug report
        // wrappers should be used so that console logs are not accidentally
        // reported to the console.

        // eslint-disable-next-line no-console
        printingFunction(
            `[${ENVIRONMENT}/${mode}: ${now.toISOString()}]`,
            ...args
        );
    } else if (mode !== "INFO") {
        void reportAnalyticsEvent({
            eventType: AnalyticsEventType.log,
            label: mode,
            metadata: {
                [AnalyticsMetadataKeys.environment]: ENVIRONMENT,
                [AnalyticsMetadataKeys.timestamp]: now.toISOString(),
                [AnalyticsMetadataKeys.payload]: args.join(" "),
            },
        });
    }
};

/**
 * Describe an error message. Should only be used for errors that are not
 * thrown, in situations which are **potentially** erroneous. Only prints to
 * console when in debug environment, otherwise reports to analytics.
 * @param args - Components of the error message.
 */
export const reportError = (...args: any[]) => {
    if (args.every((arg) => arg instanceof WezooError)) {
        return;
    }

    // eslint-disable-next-line no-console
    console.trace();

    // eslint-disable-next-line no-console
    report(console.error, "ERROR", ...args);
};

/**
 * Post an informational message. Not reported to analytics. Useful for
 * debugging.
 * @param args - Components of the info message.
 */
export const reportInfo = (...args: any[]) => {
    // eslint-disable-next-line no-console
    report(console.info, "INFO", ...args);
};

/**
 * Post a log message. Only prints to console when in debug environment,
 * otherwise reported to analytics as [AnalyticsEventType.log] event. **Make
 * sure that no personal data is included in the arguments.**
 * @param args - Components of the log message.
 */
export const reportLog = (...args: any[]) => {
    // eslint-disable-next-line no-console
    report(console.log, "LOG", ...args);
};
