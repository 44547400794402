import { prettyPrintObjectToString } from "@/common/application/prettyPrintObjectToString";
import { WezooError } from "@/common/domain/errors/_base/WezooError";

export const generateErrorMailtoLink = (error: Error | WezooError) => {
    const message = prettyPrintObjectToString(error);
    const stack = error.stack ?? "No error stack.";

    const currentUrl = window.location.href;

    const body = `Hey Wezoo Team,
                    
I ran into an issue while using your website. I was on

${encodeURIComponent(currentUrl)}

and encountered the following error:

${message}

${stack}

Please help me resolve this issue. Thanks!`.replaceAll("\n", "%0D%0A");
    return `mailto:tech@wezoo.com?subject=I encountered an error on Wezoo&body=${body}`;
};
