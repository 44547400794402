"use client";

import { reportInfo } from "@/common/application/debug";
import { AnalyticsIntegration } from "@/features/analytics/domain/entities/AnalyticsIntegration";
import { AnalyticsTools } from "@/features/analytics/domain/entities/AnalyticsTools";
import { DeepReadonly } from "ts-essentials";
import { createStore } from "zustand";

interface AnalyticsStoreState {
    // Which analytics integration is being used.
    integration: AnalyticsIntegration | null;
    // The tools for using the analytics integration.
    tools: AnalyticsTools | null;
}

interface AnalyticsStore extends AnalyticsStoreState {
    // Allows setting the integration to be used by the app analytics.
    setIntegration: (
        integration: AnalyticsIntegration,
        tools: AnalyticsTools
    ) => void;
}

const getAnalyticsStoreInitialState = (): AnalyticsStoreState => ({
    integration: null,
    tools: null,
});

/**
 * Stores the current integration being used for analytics and allows accessing
 * its tools anywhere in the app.
 */
const analyticsStore = createStore<DeepReadonly<AnalyticsStore>>((set) => ({
    ...getAnalyticsStoreInitialState(),
    setIntegration: (
        integration: AnalyticsIntegration,
        tools: AnalyticsTools
    ) => {
        reportInfo(`Setting analytics integration to: ${integration}.`);
        set((prevState) => ({ ...prevState, integration, tools }));
    },
}));

export const { getState: accessAnalyticsStore } = analyticsStore;

export default accessAnalyticsStore;
