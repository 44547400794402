import { prettyPrintObjectToString } from "@/common/application/prettyPrintObjectToString";
import { _WezooErrorBuilder } from "@/common/domain/errors/_base/WezooErrorBuilder";
import { reportAnalyticsEvent } from "@/features/analytics/application/reportAnalyticsEvent";
import { AnalyticsMetadataKeys } from "@/features/analytics/domain/constants/AnalyticsMetadataKeys";
import { AnalyticsEventType } from "@/features/analytics/domain/entities/AnalyticsEventType";

export type WezooErrorMetadata = Record<string, string | number | undefined>;
export type WezooErrorFunction<TPayload> = (
    ...args: any[]
) => WezooError<TPayload>;

export interface WezooErrorProps<TPayload = undefined> {
    /**
     * Builder used to build the error.
     */
    builder: _WezooErrorBuilder<any>;
    /**
     * Used as the unique identifier for the error.
     */
    errorName: string;
    /**
     * What kind of overall class the error is.
     */
    errorType: string;
    /**
     * Description of the error.
     */
    internalMessage: string;
    /**
     * Message show to the user.
     */
    userFacingMessage?: string;
    /**
     * What kind of analytics event is it. Typically,
     * [AnalyticsEventType.error].
     */
    analyticsEventType: AnalyticsEventType;
    /**
     * Who/what triggered this error.
     */
    triggeredBy?: string;
    /**
     * Whether to allow analytics to be reported.
     */
    enabledAnalytics?: boolean;
    /**
     * Metadata that begins with a double underscore [__] is not reported to
     * the
     * analytics. Private kind of metadata should be general to each
     * [WezooErrorType], and not added to specific error instances created with
     * the error builders. **Prefer to only add metadata that is also reported
     * to the analytics.**
     */
    metadata?: WezooErrorMetadata;
    /**
     * Payload that is attached to the error, and the catcher of the error can
     * use in error handling.
     */
    payload: TPayload;
}

export class WezooError<TPayload = undefined> extends Error {
    protected builder: _WezooErrorBuilder<any>;

    protected errorName: string;
    protected errorType: string;

    protected internalMessage: string;
    protected userFacingMessage: string | undefined;

    protected analyticsEventType: AnalyticsEventType;
    protected enabledAnalytics: boolean;

    protected metadata: WezooErrorMetadata;

    protected payload: TPayload;
    protected triggeredBy: string | undefined;

    constructor(props: WezooErrorProps<TPayload>) {
        super(
            `${props.internalMessage} || ${prettyPrintObjectToString(props)}`
        );

        this.builder = props.builder;

        this.errorName = props.errorName;

        this.errorType = props.errorType;

        this.internalMessage = props.internalMessage;
        this.userFacingMessage = props.userFacingMessage;
        this.metadata = props.metadata ?? {};

        this.analyticsEventType = props.analyticsEventType;
        this.enabledAnalytics = props.enabledAnalytics ?? true;

        this.payload = props.payload;
        this.triggeredBy = props.triggeredBy;
    }

    getUserFacingMessage(): string | undefined {
        return this.userFacingMessage;
    }

    getPayload(): TPayload {
        return this.payload;
    }

    isOfErrorType = <TComparisonPayload>(
        error: WezooErrorFunction<TComparisonPayload>
    ): this is WezooError<TComparisonPayload> => {
        return this.errorName === error.name;
    };

    wasBuiltWith = <TBuilder extends new () => _WezooErrorBuilder<any>>(
        builder: TBuilder
    ) => {
        return this.builder.__errorType === new builder().__errorType;
    };

    reportIfAnalyticsEnabled = async (): Promise<void> => {
        return this._reportIfAnalyticsEnabled();
    };

    stringify(): string {
        return prettyPrintObjectToString(this);
    }

    protected _reportIfAnalyticsEnabled = async (
        additionalMetadata?: Omit<
            WezooErrorMetadata,
            typeof AnalyticsMetadataKeys.internalErrorMessage
        >
    ) => {
        if (!this.enabledAnalytics) {
            return Promise.resolve();
        }

        return reportAnalyticsEvent({
            eventType: this.analyticsEventType,
            label: `wezoo-error/${this.errorType}/${this.errorName}`,
            metadata: {
                ...this.metadata,
                [AnalyticsMetadataKeys.internalErrorMessage]:
                    this.internalMessage,
                [AnalyticsMetadataKeys.triggeredBy]: this.triggeredBy,
                ...(this.payload
                    ? {
                          [AnalyticsMetadataKeys.payload]:
                              prettyPrintObjectToString(this.payload),
                      }
                    : {}),
                ...additionalMetadata,
            },
        });
    };
}
