"use client"; // Error components must be Client Components

import { generateErrorMailtoLink } from "@/app/(application)/generateErrorMailtoLink";
import { DiscoverRouteApi } from "@/app/(main)/discover/[[...params]]/pageApi";
import { reportError } from "@/common/application/debug";
import { addSpaceBetweenStyle } from "@/component-library/components/organising-content/spacing-styles.css";
import ExclusivePageLayout from "@/component-library/components/page-layouts/exclusive/ExclusivePageLayout";
import SetupStyling from "@/component-library/SetupStyling";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import { clsx } from "clsx";
import React, { useEffect } from "react";

export default function Error({
    error,
}: {
    error: Error & { digest?: string };
    reset: () => void;
}) {
    const mailto = generateErrorMailtoLink(error);

    useEffect(() => {
        reportError(error);
    }, [error]);

    return (
        <html>
            <SetupStyling as="body">
                <ExclusivePageLayout
                    sidebar={
                        <div>
                            <p
                                style={assignInlineVars({
                                    color: "red",
                                    fontWeight: "bold",
                                })}
                            >
                                {error.message}
                            </p>
                            <p>{error.stack}</p>
                        </div>
                    }
                >
                    <div className={clsx(addSpaceBetweenStyle)}>
                        <h2>Oops, something went wrong!</h2>
                        <p>
                            We are sorry, but an error occurred. We really want
                            to ensure the best experience for you, so please let
                            us know what happened by sending an email to our
                            support team:
                        </p>
                        <a href={mailto}>Send an email</a>
                        <p>
                            Thanks for helping us improve your experience.
                            Please continue finding a workspace{" "}
                            <a href={DiscoverRouteApi.getRoute({})}>
                                on the map
                            </a>
                            .
                        </p>
                    </div>
                </ExclusivePageLayout>
            </SetupStyling>
        </html>
    );
}
