import { RegisterAnimationPlugins } from "@/component-library/animation/gsap/RegisterAnimationPlugins";
import { themeClass } from "@/component-library/themes/theme.css";
import { clsx } from "clsx";
import React, { ElementType, JSX, PropsWithChildren, ReactNode } from "react";
import "@/component-library/themes/global-styles/index";

interface SetupStylingProps {
    as: keyof JSX.IntrinsicElements & PropsWithChildren;
    children: ReactNode;
}

const SetupStyling: React.FC<SetupStylingProps> = ({ as, children }) => {
    const Wrapper = as as ElementType;

    return (
        <Wrapper className={clsx(themeClass)}>
            <RegisterAnimationPlugins />
            {children}
        </Wrapper>
    );
};

export default SetupStyling;
